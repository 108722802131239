 
        import UserMenu from "@/components/UserMenu.vue";
        import {onMounted, ref, computed,reactive} from "vue";
        import axios from "axios";
        import { useStore } from "vuex";
        import { useRouter } from "vue-router";

        import {User} from "@/classes/user";
        
        
        
          export default{
            name:"Dashboard",
            components:{UserMenu},
        
            setup(){

              
              
              /*const message= ref('You Are Not Authenticated');
              const store = useStore();
              const auth = computed(() => store.state.auth);
              const router =useRouter();*/
              const auth = computed(() => store.state.auth);

              const router = useRouter();
              const user = ref(null);
              const store = useStore();

              
  
        
              onMounted(async () => {
        
              /*  try{
              const {data} = await axios.get('user');
              
              message.value = `Hi ${data.first_name} ${data.last_name}`; 
        
              await store.dispatch('setAuth', true);
            } catch(e){
        
              await store.dispatch('setAuth', false);

              await router.push('/login');  //send the user out the dashboard when the user is not Authenticated:
          
            }
           
      
              });
        
             return{
                message,
                auth
               
              }
        */
        try {
        const response = await axios.get('user');

        const u = response.data.data;

        await store.dispatch('User/setUser', new User(
            u.id,
            u.first_name,
            u.last_name,
            u.email,
            u.mobile,
            u.city,
            u.country,
            u.role,
            u.permissions
        ));

        user.value = u;
        await store.dispatch('setAuth', true);
        
      } catch (e) {
        await store.dispatch('setAuth', false);

        
        await router.push('/login');
      }
    });

    return {
      user,
      auth
    };

        
            }
        
        
        
        
          }
        
        
        
        