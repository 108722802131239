<template>
    <div class="three-column-footer-contact-form-container">
  <footer class="three-column-footer-contact-form" data-equalizer data-equalize-by-row="true">
    <h3  class="portfolio-resume-header">Contact Us</h3>
    <div class="footer-left" data-equalizer-watch>
      <div class="baseline">
        <div class="contact-details">
          <h6>Contact details</h6>
          <p><i class="large fa fi-telephone" aria-hidden="true"></i> (+255) 763 993 249</p>
          <p><i class="large fa fi-mail" aria-hidden="true"></i> maulizo@ncf.cotz </p>
          <p><i class="large fa fi-marker" aria-hidden="true"></i> Sinza Lego, Dar es salaaam, Tanzania</p>
        </div>
        
      </div>
     </div>
    <div class="footer-center" data-equalizer-watch>
      <div class="baseline">
        <div class="newsletter">
          <h6>Enquiry form</h6>
          <div class="input-group">
            <input class="input-group-field" type="text" placeholder="Name">
            <input class="input-group-field" type="email" placeholder="Email address">
          </div>
          <div class="input-group">

            <textarea placeholder="Message"></textarea>
          </div>

          <a class="button " href="#">Send Enquiry</a>
        </div>
      </div>
    </div>
    <div class="footer-right" data-equalizer-watch>
      <div class="baseline">
        
        <h6>Working Hours</h6>
        <p>Mon - Fri 9:00am - 5:00pm</p>
        <p>Sat 9:00am - 8:00pm</p>
        <p>Sun 9:00am - 4:00pm</p>
        <div class="social">
            <i style="font-size:32px; padding: 5px;" class="fi-social-facebook" aria-hidden="true"></i>
            <i style="font-size:32px; padding: 5px;" class="fi-social-twitter" aria-hidden="true"></i>
            <i style="font-size:32px; padding: 5px;" class="fi-social-instagram fa-3x" aria-hidden="true"></i>
            <i style="font-size:32px; padding: 5px;" class="fi-social-linkedin fa-3x" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </footer>
</div>


</template>