import { ActionContext, createStore } from 'vuex'
import UserModule from "@/store/UserModule";


export default createStore({
  state: {
    auth:false


  },
  getters: {
  },
  mutations: {
    setAuth(state: { auth:boolean}, auth: boolean){
      state.auth = auth;
    }
  },
  actions: {
    setAuth(context:ActionContext<any, any>, auth:boolean){
      context.commit('setAuth',auth);

    }
  },
  modules: {
    User: UserModule
  }
})
