<template>

    

    <div  class="news-card-tag" >
    <span class="label"><a href="#"></a></span>
    <span class="label"><a href="#">{{ topic.class_level}}</a></span>
    </div>
    <div class="card news-card">
      <img :src="topic.image" />
    <div class="card-section">
    <div class="news-card-date">{{topic.topic_date}}</div>
    <article class="news-card-article">
    <h4 class="news-card-title"><router-link :to="`/topics/${topic.id}/view-topic`">
             {{ topic.title }} </router-link></h4>
    <p class="news-card-description">{{topic.description}}</p>
    </article>
    <div class="news-card-author">
    <div class="news-card-author-image">
      <img src="../assets/img/author.png" alt="Author">
    </div>
    <div class="news-card-author-name">
      By <router-link to="/about">Emmanuel P. Mgisha</router-link>
    </div>
    </div>
    </div>

    
    
   
                
    
             
      
    </div>  <!---End of the cards container-->
    
    


    <!--<div class="card">
        <div class="card-image">
            <figure class="image is-4by3">
                <img :src="topic.image" alt="Placeholder image" height="50"/>
            </figure>
        </div>

        <div class="card-content">
            <div class="media">
                <div class="media-content">
                    <p class="is-size-5">{{ topic.title }}</p>
                </div>
            </div>

            <div class="content">
                <p>{{ topic.description }}</p>

                <router-link :to="{name: 'Topic', params: {slug: topic.id}}">More</router-link>
            </div>
        </div>
    </div>-->
</template>

<script>
export default {
    props: ['topic']
}
</script>