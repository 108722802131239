
import { onMounted, ref } from "vue";
import axios from 'axios';
import {useRoute} from "vue-router";
import PDFJSViewer from "@/components/PDFJSViewer.vue";
export default {

    name:"TopicView",
    components: {PDFJSViewer},
 
   /* props: ["id"],
    data() {
        return {
            topics: "",
        };
    },*/

   

    setup(){
        const topics = ref([]);
        const {params} = useRoute();
   
      onMounted(async () => {
     const response = await axios.get(`topics/${params.id}`);

      topics.value = response.data.data;
    });

    return {
        topics
      }
        
    },

    data () {
   
    return {
      name:'demo.pdf', //change which pdf file loads
      path: 'lib/web/viewer.html' //path of the PDF.js viewer.html
    }
  }

}
