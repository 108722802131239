
import {ref, onMounted} from 'vue';
import axios from 'axios';
import {useRoute, useRouter} from "vue-router";
import {User} from "@/classes/user";

export default {
  name: "UsersEdit",
  setup() {
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const mobile = ref('');
    const city =ref('');
    const country=ref('');
    const roleId = ref(0);
    const roles = ref([]);
    const router = useRouter();
    const {params} = useRoute();

    onMounted(async () => {
      const response = await axios.get('roles');

      roles.value = response.data.data;
      

      const userCall = await axios.get(`users/${params.id}`);

      const user: User = userCall.data.data;

      firstName.value = user.first_name;
      lastName.value = user.last_name;
      email.value = user.email;
      mobile.value = user.mobile;
      city.value = user.city;
      country.value = user.country;
      roleId.value = user.role.id;
      
    });

    const submit = async () => {
      await axios.put(`users/${params.id}`, {
        first_name: firstName.value,
        last_name: lastName.value,
        email: email.value,
        mobile: mobile.value,
        city: city.value,
        country: country.value,
        role_id: roleId.value
        
        
      });

      await router.push('/users');
    }

    return {
      firstName,
      lastName,
      email,
      mobile,
      city,
      country,
      roleId,
      roles,
      submit
    }
  }
}
