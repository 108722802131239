
  import {ref, onMounted, computed} from 'vue';
  import axios from 'axios';
  import {Entity} from "@/interfaces/entity";
  import Paginator from "@/components/Paginator.vue";
  import {useStore} from "vuex";
  import PDFJSViewer from '@/components/PDFJSViewer.vue';
  
  export default {
    name: "Topics",
    components: {Paginator,PDFJSViewer},
    
    setup() {
      const topics = ref([]);
      const lastPage = ref(0);
      const store = useStore();
  
      const user = computed(() => store.state.User.user);
  
      const load = async (page = 1) => {
        const response = await axios.get(`topics?page=${page}`);
  
        topics.value = response.data.data;
        lastPage.value = response.data.meta.last_page;
      }
  
      onMounted(load);
  
      const del = async (id: number) => {
        if (confirm('Are you sure you want to delete this record?')) {
          await axios.delete(`topics/${id}`);
  
          topics.value = topics.value.filter((e: Entity) => e.id !== id);
        }
      }
  
      return {
        topics,
        lastPage,
        user,
        del,
        load
      }
    }/*,
    data () {
    const  name= 'demo.pdf';
    return {
      name, //change which pdf file loads
      path: 'lib/web/viewer.html' //path of the PDF.js viewer.html
    }
  }*/
    
  }
  