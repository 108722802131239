import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "product-card" }
const _hoisted_2 = { class: "product-card-title" }
const _hoisted_3 = { href: "#" }
const _hoisted_4 = { class: "product-card-desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PDFJSViewer = _resolveComponent("PDFJSViewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, [
      _createElementVNode("a", _hoisted_3, _toDisplayString($setup.topics.title), 1)
    ]),
    _createElementVNode("span", _hoisted_4, _toDisplayString($setup.topics.class_level), 1),
    _createVNode(_component_PDFJSViewer, {
      path: `${$data.path}`,
      fileName: `${$data.name}`
    }, null, 8, ["path", "fileName"])
  ]))
}