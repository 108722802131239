
  import {ref, onMounted} from 'vue';
  import axios from 'axios';
  import {useRoute, useRouter} from "vue-router";
  import ImageUpload from "@/components/ImageUpload.vue";
  import {Topics} from "@/classes/topics";
  
  export default {
    name: "TopicsEdit",
    components: {ImageUpload},
    setup() {
      const title = ref('');
      const description = ref('');
      const image = ref('');
      const price = ref(0);
      const router = useRouter();
      const {params} = useRoute();
  
      onMounted(async () => {
        const response = await axios.get(`topics/${params.id}`);
  
        const topics: Topics = response.data.data;
  
        title.value = topics.title;
        description.value = topics.description;
        image.value = topics.image;
        price.value = topics.price;
      });
  
      const submit = async () => {
        await axios.put(`topics/${params.id}`, {
          title: title.value,
          description: description.value,
          image: image.value,
          price: price.value,
        });
  
        await router.push('/topics');
      }
  
      return {
        title,
        description,
        image,
        price,
        submit
      }
    }
  }
  