
  import {ref, onMounted} from 'vue';
  import axios from 'axios';
  import {useRoute, useRouter} from "vue-router";
  import {Category} from "@/classes/category";
  
  export default {
    name: "CategoryEdit",
    setup() {
      const name = ref('');
      const router = useRouter();
      const {params} = useRoute();
  
      onMounted(async () => {
      const roleCall = await axios.get(`topics-categories/${params.id}`);

      const category: Category = roleCall.data.data;

      name.value = category.name;

      });
  
      
  
      const submit = async () => {
        await axios.put(`topics-categories/${params.id}`, {
          name: name.value,
         
        });
  
        await router.push('/categories');
      }
  
    
  
      return {
        name,
        submit
      
      }
    }
  }
  