
import axios from 'axios';
import { onMounted, ref } from 'vue';
import Paginator from "@/components/Paginator.vue";
import {Entity} from "@/interfaces/entity";




  export default{

    name:"BachelorDegree",
    components:{Paginator},

    setup(){

      const topics=ref([]);
      const lastPage = ref(0);
      
        const load = async (page = 1) => {
        const response = await axios.get(`topics?page=${page}`);
  

        topics.value=response.data.data
        lastPage.value = response.data.meta.last_page;
        
      }


      onMounted(load);
  
      const del = async (id: number) => {
        if (confirm('Are you sure you want to delete this record?')) {
          await axios.delete(`topics/${id}`);
  
          topics.value = topics.value.filter((e: Entity) => e.id !== id);
        }
      }

      return {
        topics,
        load,
        lastPage
      }
   

    }


  }

