
import {ref, onMounted, computed} from 'vue';
import axios from 'axios';
import {User} from "@/classes/user";
import {useStore} from "vuex";


export default {
  name: "Profile",
  setup() {
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const mobile = ref('');
    const city = ref('');
    const country = ref('');
    const password = ref('');
    const passwordConfirm = ref('');
    const store = useStore();

    onMounted(async () => {
      const user = computed(() => store.state.User.user);

      firstName.value = user.value.first_name;
      lastName.value = user.value.last_name;
      email.value = user.value.email;
      mobile.value = user.value.mobile;
      city.value = user.value.city;
      country.value = user.value.country;
    });

    const submitInfo = async () => {
      const response = await axios.put('userinfo', {
        first_name: firstName.value,
        last_name: lastName.value,
        email: email.value
      });

      const u: User = response.data;

      await store.dispatch('User/setUser', new User(
          u.id,
          u.first_name,
          u.last_name,
          u.email
          //u.role,
          //u.permissions
      ));
    }

    const submitPassword = async () => {
      await axios.put('userpassword', {
        password: password.value,
        password_confirm: passwordConfirm.value
      });

      password.value = '';
      passwordConfirm.value = '';
    }

    return {
      firstName,
      lastName,
      email,
      mobile,
      city,
      country,
      password,
      passwordConfirm,
      submitInfo,
      submitPassword
    }
  }
}
