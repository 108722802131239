
import {ref, onMounted} from 'vue';
import axios from 'axios';
import {useRouter} from "vue-router";

export default {
  name: "RolesCreate",
  setup() {
    const name = ref('');
    const permissions = ref([]);
    const selected = ref([] as number[]);
    const router = useRouter();

    onMounted(async () => {
      const response = await axios.get('permissions');

      permissions.value = response.data.data;
    });

    const select = (id: number, checked: boolean) => {
      if (checked) {
        selected.value = [...selected.value, id];
        return;
      }

      selected.value = selected.value.filter(s => s !== id);
    }

    const submit = async () => {
      await axios.post('roles', {
        name: name.value,
        permissions: selected.value
      });

      await router.push('/roles');
    }

    return {
      name,
      permissions,
      select,
      submit
    }
  }
}
