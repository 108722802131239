<template>
  <!---
<div class="app-dashboard shrink-medium">
  <div class="row expanded app-dashboard-top-nav-bar">-->
    <!--<div class="columns medium-2">
      <button data-toggle="app-dashboard-sidebar" class="menu-icon hide-for-medium"></button>-->
      <!---<a class="app-dashboard-logo">{{message}}</a>
    </div> -->
    <!---<div class="columns show-for-medium">
      <div class="app-dashboard-search-bar-container">
        <input class="app-dashboard-search" type="search" placeholder="Search">
        <i class="app-dashboard-search-icon fa fa-search"></i>
      </div>
    </div>
    <div class="columns shrink app-dashboard-top-bar-actions">
      <button href="#" class="button hollow">Logout</button>
      <a href="#" height="30" width="30" alt=""><i class="fa fa-info-circle"></i></a>
    </div>
  </div>
  </div>
  -->



<div class="grid-container">
  
<h2 class="portfolio-hero-header-h1"></h2>
<div class="grid-x grid-padding-x align-center-middle" style="margin-bottom:1.5rem ;">

<div class="cell small-4">

  <div class="circle-graph" data-circle-graph data-percent="50">
  <div class="circle-graph-progress">
    <div class="circle-graph-progress-fill"></div>
  </div>
  <div class="circle-graph-percents">
    <div class="circle-graph-percents-wrapper">
      <span class="circle-graph-percents-number"></span>
      <span class="circle-graph-percents-units">of 100</span>
    </div>
  </div>
</div>



</div>


<div class="cell small-6">


  <div class="circle-graph" data-circle-graph data-percent="50">
  <div class="circle-graph-progress">
    <div class="circle-graph-progress-fill"></div>
  </div>
  <div class="circle-graph-percents">
    <div class="circle-graph-percents-wrapper">
      <span class="circle-graph-percents-number"></span>
      <span class="circle-graph-percents-units">of 100</span>
    </div>
  </div>
</div>

</div>

</div>





</div>  <!---End of the cards container-->

    
<!--<div class="courses">
        <div class="hero is-info">
            <div class="hero-body has-text-centered">
                <h1 class="title">Topics</h1>
            </div>
        </div>
        <section class="section">
            <div class="container">
                <div class="columns">
                    <div class="column is-2">
                        <aside class="menu">
                            <p class="menu-label">Categories</p>
                        -->

   <!--      
<header class="subnav-hero-section">
  <h1 class="subnav-hero-headline">Browse  <small>Topics</small></h1>
 <ul class="subnav-hero-subnav">
    <li><a href="#">Take Action!</a></li>
    <li><a href="#" class="is-active">Carpe Diem!</a></li>
    <li><a target="_blank" href="https://zurb.com/responsive">Just do it!</a></li>
  </ul>
</header>-->



        <div class="row expanded app-dashboard-top-nav-bar" style="margin-bottom:2.5rem ;">
        <div class="columns shrink app-dashboard-top-bar-actions">
        <button class="button hollow"
                                        v-bind:class="{'is-active': !activeCategory}"
                                        @click="setActiveCategory(null)"
                                    >
                                        All Topics
                                      </button>
          
        </div>
        <div class="columns shrink app-dashboard-top-bar-actions">
        <button href="#" class="button hollow"
                                    v-for="category in categories"
                                    v-bind:key="category.id"
                                    @click="setActiveCategory(category)"
                                    >
                                    {{ category.name }}
                               
                                    </button>
          
        </div>
        <!---<div class="columns shrink app-dashboard-top-bar-actions">
         <router-link to="/BachelorLevel"><button href="#" class="button hollow">Bachelor Degree</button></router-link> 
          
        </div>
        <div class="columns shrink app-dashboard-top-bar-actions">
        <router-link to="/ElectricalEngineering"> <button href="#" class="button hollow">Electrical Engineering Modules</button></router-link>
          
        </div>--->
      </div>
      
                            <!---<ul class="menu-list">
                                <li>
                                    <a  
                                        v-bind:class="{'is-active': !activeCategory}"
                                        @click="setActiveCategory(null)"
                                    >
                                        All Topics
                                    </a>
                                </li>
                                <li
                                    v-for="category in categories"
                                    v-bind:key="category.id"
                                    @click="setActiveCategory(category)"
                                >
                                    <a>{{ category.name }}</a>
                                </li>
                            </ul>-->
                       <!--</aside>
                    </div>--> 

 <div class="grid-container">
 <div class="grid-x" data-equalizer data-equalize-on="medium" id="test-eq" >
 <div class="cell small-4" style="padding:0.4rem;" 
                                v-for="topic in topics"
                                v-bind:key="topic.id"
                            >
                                <CourseItem :topic="topic" />
                            </div>
    <ul class="pagination text-center" role="navigation" aria-label="Pagination" data-page="6" data-total="16">
    <Paginator :last-page="lastPage" @page-changed="load($event)"/>
    </ul>

                            <!---<div class="column is-12">
                                <nav class="pagination">
                                    <a class="pagination-previous">Previous</a>
                                    <a class="pagination-next">Next</a>
                                </nav>
                            
                            </div>-->
                        </div>
                    </div>
                <!---</div>


            </div>
        </section>
    </div>-->




</template>

<script>
import axios from 'axios'
import {ref, onMounted, computed} from 'vue';
import CourseItem from '@/components/CourseItem.vue'
import Paginator from "@/components/Paginator.vue";


export default {
     name:'Dashboard',

     



    data() {
        return {
            category: {},
            topics: [],
            categories: [],
            activeCategory: null
        }
    },
    components: {
        CourseItem
    },
    
    async mounted() {
        console.log('mounted')

        await axios
            .get('topics-categories')
            .then(response => {
                console.log(response.data)

                this.categories = response.data.data
            })
        
        this.getCourses()
    },
    methods: {
        setActiveCategory(category) {
            console.log(category)
            this.activeCategory = category

            this.getCourses()
        },

        
        
        getCourses() {
            let url = 'get_courses'

            if (this.activeCategory) {
                url += '?category_id=' + this.activeCategory.id
            }

            axios
                .get(url)
                .then(response => {
                    console.log(response.data)

                    this.topics = response.data
                })
        }
    }
}
</script>