
import axios from "axios";
import {reactive, ref} from "vue"
import { useRoute, useRouter } from "vue-router";

export default{
    name:'Reset',

    setup(){

        const password=ref('');
       /* const data=reactive({
            password:'',
            confirm_password:''
        });*/

        const route=useRoute();
        const router=useRouter();

        const submit= async () => {
            
           //await axios.post('passwordreset', {...data, token:route.params.token});
           await axios.post('passwordreset', {password:password.value, token:route.params.token});

           await router.push('/login');

        }

        return{
            //data
            password,
            submit
            
        }

    }
}





