<template>

<div class="cell small-4">
<div class="grid-x grid-padding-x align-center-middle">
<div class="large-9 cell">

    <div class="grid-x">
    <div class="about-us large-12 cell">

  <h3  class="portfolio-resume-header">About Us</h3>
  <p >Newton Creative Foundation blog was established on 17 April 2016 at Mbeya University of Science and Technology, 
    Emmanuel Pregrino Mgisha as both the founder and author of the Engineering mathematics materials, Quiz, tutorials 
    (Questions and answers), self-tests, students groups discussions(given challenging problems to find their solutions) 
    to initiate fast thinking and accuracy work for correct solutions and to find solutions of past papers, past assignments 
    and currently tutorials which collected from various universities and institutes in Tanzania which obtained in 
    Newton Creative Foundation (NCF) blog. The special aim of this blog is to deal with Engineering Mathematics 
    Module both for University and Institute students who are studying either bachelor of Engineering or Mathematics 
    courses where all topics contents of each semester will be prepared in a simplified way with past assignments, 
    past papers(University Examanitions,UE), past & currently tutorials with their solutions will be solved and presented 
    in logical sequence where each University or Institute will have its own link for searching those Engineering Mathematics 
    materials according to their course contents in a particular semester(either I or II) in order to develop a greater 
    understanding of the ideas involved for better academic performance and excellence in Engineering Mathematics module as 
    both an "essential tool" and "fundamental course" to prepare students as future Engineers, Instructors and Scientists at large.</p>

  
  </div>
</div>

<div class="portfolio-resume grid-x">

<div class="cell small-4">
  <div class="portfolio-resume-wrapper">
    <img class="portfolio-resume-headshot" src="../assets/img/author.png" alt="Author">
    <h3 class="portfolio-resume-header">Skills</h3>
    <ul>
      <li>Electrical</li>
      <li>Telecommunication</li>
      <li>Drawing</li>
      <li>Author</li>
    </ul>
  </div>
</div>

<div class="cell small-4">
  <div class="portfolio-resume-wrapper">
    <h3 class="portfolio-resume-header">Experience</h3>
    <div class="portfolio-resume-spacing">
      <h5><strong>Tutorial Assistant - Electrical Engineering</strong></h5>
      <p>National Institute of Transport(NIT) </p>
      <!--<ul>
        <li>Making goats noises</li>
        <li>Cleaning their stalls</li>
        <li>Milking the goats</li>
      </ul>-->
    </div>
    <div class="portfolio-resume-spacing">
      <h5><strong>Education Background</strong></h5>
    
      <ul>
        <li>BEng Electrical and Electronics Engineering - Mbeya University of Science and Technology (MUST)</li>
        <li>Ordinary Diploma Electronic and Telecommunication Engineering- Dar-es-salaam Institute of Technology (DIT)</li>
        <li>A-Level Education - Ifunda Technical School, Azania High School</li>
      </ul>
    </div>
  </div>
</div>

<div class="cell small-4">
  <div class="portfolio-resume-wrapper">
    <h3 class="portfolio-resume-header">About Author</h3>
    <p>
        <h3 style="font-size:18px;"><strong>Emmanuel Pregrino Mgisha</strong></h3>
        <h4 style="font-size:16px;">Tutorial Assistant - Electrical Engineering</h4>
        <h5 style="font-size:15px;">National Institute of Transport(NIT)</h5>
    </p>
    
    <p>I am both the founder and author of the Newton Creative Foundation (NCF) blog, as well as I am the author of the new Basic Mathematics and Physics text books for Ordinary Level Education</p>
    <div class="portfolio-resume-contact-info">
      <a href="mailto:#"><i class="fa fa-envelope" aria-hidden="true"></i>md@ncf.co.tz</a>
    </div>
    <div class="portfolio-resume-contact-info">
      <a href="tel:+14083410600"><i class="fa fa-phone" aria-hidden="true"></i>(+255) 763 993 249</a>
    </div>
  </div>
</div>
</div>

</div>
</div>
</div>

</template>