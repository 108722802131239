
  import { computed } from "vue";
  import axios from "axios";
  import { useStore } from "vuex";

  export default{
    name:'Nav',

    setup(){

    const store = useStore();
    const auth = computed(() => store.state.auth);

    const user = computed(() => store.state.User.user);

     const logout = async () =>{

      await axios.post('logout', {}, {withCredentials:true});

      axios.defaults.headers.common['Authorization'] = '';
      await store.dispatch('setAuth', false);

     }

     return {
      auth,
      user,
      logout
     }


    }

  }
  
  