
  import {onMounted, ref,computed} from 'vue';
  import axios from 'axios';
  import {useRouter} from "vue-router";
  import ImageUpload from "@/components/ImageUpload.vue";
 
  import { useStore } from 'vuex';
import { User } from '@/classes/user';
  
  export default {
    name: "TopicsCreate",
    components: {ImageUpload},


    
    setup() {
      const title = ref('');
      
      const class_level = ref('');
      const description = ref('');
      const image = ref('');
      const price = ref(0);
      const router = useRouter();
      const categoryId = ref(0);
      const categories_data = ref([]);
      const store = useStore();

      onMounted(async () => {
      const user = computed(() => store.state.User.user);

      const response = await axios.get('topics-categories');

      categories_data.value = response.data.data;
      


    });
  
      const submit = async () => {
        await axios.post('topics', {
          title: title.value,
          class_level: class_level.value,
          description: description.value,
          image: image.value,
          price: price.value,
          categories:categoryId.value,
          
        });
  
        await router.push('/topics');
      }
  
      return {
        title,
        categories_data,
        class_level,
        description,
        image,
        price,
        categoryId,
        submit
      }
    }
  }
  