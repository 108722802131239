
import {ref, onMounted, computed} from 'vue';
import axios from 'axios';
import {Entity} from "@/interfaces/entity";
import {useStore} from "vuex";
import Paginator from "@/components/Paginator.vue";

export default {
  name: "Users",
  components: {
    Paginator
  },
  setup() {
    const users = ref([]);
    const lastPage = ref(0);
    const store = useStore();

    const authenticatedUser = computed(() => store.state.User.user);

    const load = async (page = 1) => {
      const response = await axios.get(`users?page=${page}`);

      users.value = response.data.data;
      lastPage.value = response.data.meta.last_page;
    }

    const del = async (id: number) => {
      if (confirm('Are you sure you want to delete this record?')) {
        await axios.delete(`users/${id}`);

        users.value = users.value.filter((e: Entity) => e.id !== id);
      }
    }

    onMounted(load);

    return {
      users,
      lastPage,
      authenticatedUser,
      del,
      load
    }
  }
}
