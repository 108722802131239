
  import {ref, onMounted, computed} from 'vue';
  import axios from 'axios';
  import {Entity} from "@/interfaces/entity";
  import {useStore} from "vuex";
  
  export default {
    name: "Categories",
    setup() {
      const categories = ref([]);
      const store = useStore();
  
      const user = computed(() => store.state.User.user);
  
      onMounted(async () => {
        const response = await axios.get('topics-categories');
  
        categories.value = response.data.data;
      });
  
      const del = async (id: number) => {
        if (confirm('Are you sure you want to delete this record?')) {
          await axios.delete(`topics-categories/${id}`);
  
          categories.value = categories.value.filter((e: Entity) => e.id !== id);
        }
      }
  
      return {
        categories,
        user,
        del
      }
    }
  }
  