
import { reactive } from 'vue';
import axios from "axios";
import { useRouter } from 'vue-router';

   export default{
    name: "Login",
    setup(){
      const data= reactive({
        email:'',
        password:''
      });

      const notify = reactive({

      cls:'',
      message:''
      });

      const router = useRouter();

      
      const submit = async() => {

        try{

        

        const response = await axios.post('login', data, {withCredentials:true});

        //axios.defaults.headers.common['Authorization'] = 'Bearer ${response.data.token}';
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        
        notify.cls='success';
        notify.message='Welcome User to NCF Learning Platform';

        await router.push('/dashboard');

        }catch(e){

        notify.cls='alert';
        notify.message='Restricted Area';

        }


      }
      
        return {
        data,
        notify,
        submit
        }
      }


    }


   