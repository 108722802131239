
import {ref, onMounted} from 'vue';
import axios from 'axios';
import {useRoute, useRouter} from "vue-router";
import {Role} from "@/classes/role";

export default {
  name: "RolesEdit",
  setup() {
    const name = ref('');
    const permissions = ref([]);
    const selected = ref([] as number[]);
    const router = useRouter();
    const {params} = useRoute();

    onMounted(async () => {
      const response = await axios.get('permissions');

      permissions.value = response.data.data;

      const roleCall = await axios.get(`roles/${params.id}`);

      const role: Role = roleCall.data.data;

      name.value = role.name;
      selected.value = role.permissions.map(p => p.id);
    });

    const select = (id: number, checked: boolean) => {
      if (checked) {
        selected.value = [...selected.value, id];
        return;
      }

      selected.value = selected.value.filter(s => s !== id);
    }

    const submit = async () => {
      await axios.put(`roles/${params.id}`, {
        name: name.value,
        permissions: selected.value
      });

      await router.push('/roles');
    }

    const checked = (id: number) => selected.value.some(s => s === id)

    return {
      name,
      permissions,
      select,
      submit,
      checked
    }
  }
}
