import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import '../assets/css/app.css'
import '../assets/js/app.js'
import '../assets/css/foundation-icons/foundation-icons.css'

import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Contactus from '@/views/Contactus.vue'
import Dashboard from '@/securedUser/Dashboard.vue'
import Forgot from '@/views/Forgot.vue'
import Reset from '@/views/Reset.vue'
import Profile from '@/securedUser/Profile.vue'
import securedUser from '@/securedUser/securedUser.vue'
import OrdinaryLevel from '@/securedUser/OrdinaryLevel.vue'
import DiplomaLevel from '@/securedUser/DiplomaLevel.vue'
import BachelorLevel from '@/securedUser/BachelorLevel.vue'
import ElectricalEngineering from '@/securedUser/ElectricalEngineering.vue'
import Users from '@/securedUser/admin/users/Users.vue'
import UsersCreate from '@/securedUser/admin/users/UsersCreate.vue'
import UsersEdit from '@/securedUser/admin/users/UsersEdit.vue'
import Roles from "@/securedUser/admin/roles/Roles.vue";
import RolesCreate from "@/securedUser/admin/roles/RolesCreate.vue";
import RolesEdit from "@/securedUser/admin/roles/RolesEdit.vue";
import Topics from "@/securedUser/admin/topics/Topics.vue";
import TopicsCreate from "@/securedUser/admin/topics/TopicsCreate.vue";
import TopicsEdit from "@/securedUser/admin/topics/TopicsEdit.vue";
import TopicsView from "@/securedUser/admin/topics/TopicsView.vue";
import Categories from "@/securedUser/admin/categories/Categories.vue";
import CategoriesCreate from "@/securedUser/admin/categories/CategoriesCreate.vue";
import CategoriesEdit from "@/securedUser/admin/categories/CategoriesEdit.vue";




const routes: Array<RouteRecordRaw> = [

  { path:'/login', component:Login},
  { path:'/register', component:Register},
  { path: '/', component:Home},
  { path: '/About', component:About},
  { path: '/Contactus', component:Contactus},
  { path:'/Forgot', component:Forgot},
  { path:'/Reset/:token', component:Reset},
  
  
  
  {
    path:'/',
    component:securedUser,
    children: [
      {path: '', redirect: '/dashboard'},
            {path: '/dashboard', component: Dashboard},
            {path: '/profile', component: Profile},
            {path: '/OrdinaryLevel', component:OrdinaryLevel},
            {path: '/DiplomaLevel', component:DiplomaLevel},
            {path: '/BachelorLevel', component:BachelorLevel},
            {path: '/ElectricalEngineering', component:ElectricalEngineering},
            {path: '/Users', component:Users},
            {path: '/users/create', component: UsersCreate},
            {path: '/users/create', component: UsersCreate},
            {path: '/users/:id/edit', component: UsersEdit},
            {path: '/roles', component: Roles},
            {path: '/roles/create', component: RolesCreate},
            {path: '/roles/:id/edit', component: RolesEdit},
            {path: '/topics', component: Topics},
            {path: '/topics/create', component: TopicsCreate},
            {path: '/topics/:id/edit', component: TopicsEdit},
            {path: '/topics/:id/view-topic', component: TopicsView},

            {path: '/categories', component: Categories},
            {path: '/categories/create', component: CategoriesCreate},
            {path: '/categories/:id/edit', component: CategoriesEdit},
            
            
            
    ]
  }

  
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
