<template>
 

    <div class="grid-container">
    
    <div class="grid-x">
      <!---Start of the cards-->
    <div class="grid-container">
    <h2 class="portfolio-hero-header-h1">Electrical Engineering Module</h2>
    <div class="grid-x">
    
    <div class="cell small-4">
    
    <div class="news-card-tag">
    <span class="label"><a href="#">Engineering Mathematics</a></span>
    <span class="label"><a href="#">Bachelor Degree</a></span>
    </div>
    <div class="card news-card">
    <img src="../assets/img/numerical-fulltopic.png">
    <div class="card-section">
    <div class="news-card-date">Sunday, 16th April</div>
    <article class="news-card-article">
    <h4 class="news-card-title"><a href="#">Engineering Mathematics-UQ7B</a></h4>
    <p class="news-card-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae facere, ipsam quae sit, eaque perferendis commodi!...</p>
    </article>
    <div class="news-card-author">
    <div class="news-card-author-image">
      <img src="../assets/img/author.png" alt="Author">
    </div>
    <div class="news-card-author-name">
      By <a href="#">Emmanuel P. Mgisha</a>
    </div>
    </div>
    </div>
    </div>
    
    
    </div>
    
    
    <div class="cell small-4">
    
    <div class="news-card-tag">
    <span class="label"><a href="#">Engineering Mathematics</a></span>
    <span class="label"><a href="#">Bachelor Degree</a></span>
    </div>
    <div class="card news-card">
    <img src="../assets/img/basic-mathematics-form-three.png">
    <div class="card-section">
    <div class="news-card-date">Sunday, 17th April</div>
    <article class="news-card-article">
    <h4 class="news-card-title"><a href="#">Fourier Series UQF7-B</a></h4>
    <p class="news-card-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae facere, ipsam quae sit, eaque perferendis commodi!...</p>
    </article>
    <div class="news-card-author">
    <div class="news-card-author-image">
      <img src="../assets/img/author.png" alt="Author">
    </div>
    <div class="news-card-author-name">
      By <a href="#">Emmanuel P. Mgisha</a>
    </div>
    </div>
    </div>
    </div>
    
    </div>
    
    <div class="cell small-4">
    
    <div class="news-card-tag">
    <span class="label"><a href="#">Engineering Mathematics</a></span>
    <span class="label"><a href="#">Bachelor Degree</a></span>
    </div>
    <div class="card news-card">
    <img src="../assets/img/NA-UQF7B-.png">
    <div class="card-section">
    <div class="news-card-date">Monday, 29th April</div>
    <article class="news-card-article">
    <h4 class="news-card-title"><a href="#">Statistics Laplace Transform-UQ7-B</a></h4>
    <p class="news-card-description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae facere, ipsam quae sit, eaque perferendis commodi!...</p>
    </article>
    <div class="news-card-author">
    <div class="news-card-author-image">
      <img src="../assets/img/author.png" alt="Author">
    </div>
    <div class="news-card-author-name">
      By <a href="#">Harry Manchanda</a>
    </div>
    </div>
    </div>
    </div>
     
    </div>
    
    </div>
    
    
    <ul class="pagination text-center" role="navigation" aria-label="Pagination" data-page="6" data-total="16">
    <li class="pagination-previous disabled">Previous <span class="show-for-sr">page</span></li>
    <li class="current"><span class="show-for-sr">You're on page</span> 1</li>
    <li><a href="#" aria-label="Page 2">2</a></li>
    <li><a href="#" aria-label="Page 3">3</a></li>
    <li><a href="#" aria-label="Page 4">4</a></li>
    <li class="ellipsis" aria-hidden="true"></li>
    <li><a href="#" aria-label="Page 12">12</a></li>
    <li><a href="#" aria-label="Page 13">13</a></li>
    <li class="pagination-next"><a href="#" aria-label="Next page">Next <span class="show-for-sr">page</span></a></li>
    </ul> 
      
    </div>  <!---End of the cards container-->
    
        <div class="cell small-3">
    
    
        </div>
    
    </div>
    
    </div>
    </template>
    