
import {ref, onMounted} from 'vue';
import axios from 'axios';
import {useRouter} from "vue-router";

export default {
  name: "UsersCreate",
  setup() {
    const firstName = ref('');
    const lastName = ref('');
    const email = ref('');
    const mobile = ref('');
    const city =ref('');
    const country=ref('');
    const roleId = ref(0);
    const roles = ref([]);
    const router = useRouter();

    onMounted(async () => {
      const response = await axios.get('roles');

      roles.value = response.data.data;
    });

    const submit = async () => {
      await axios.post('users', {
        first_name: firstName.value,
        last_name: lastName.value,
        email: email.value,
        mobile: mobile.value,
        city: city.value,
        country: country.value,
        role_id: roleId.value
      });

      await router.push('/users');
    }

    return {
      firstName,
      lastName,
      email,
      mobile,
      city,
      country,
      roleId,
      roles,
      submit
    }
  }
}
