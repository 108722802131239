
    import {ref, onMounted} from 'vue';
    import axios from 'axios';
    import {useRouter} from "vue-router";
    
    export default {
      name: "CategoryCreate",
      setup() {
        const name = ref('');
        const router = useRouter();

    
        
    
        const submit = async () => {
          await axios.post('topics-categories', {
            name: name.value,
          });
    
          await router.push('/categories');
        }
    
        return {
          name,
          submit
        }
      }
    }
    