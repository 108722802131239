
import {reactive} from "vue";
import axios from 'axios';
import {useRouter} from "vue-router";

export default{
  name:"Login",
  setup(){
    const data= reactive({
     first_name:'',
     last_name:'',
     email:'',
     password:'',
     mobile:'',
     city:'',
     country:'',
     role:3

    });

    const router = useRouter();

    const submit = async() => {
        await axios.post('register', data);
        console.log(123);

        await router.push('/login');

    }

    return {
        data, submit
    }

    }
}
