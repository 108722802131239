
import {ref,reactive} from  "vue";
import axios from "axios";

export default{

    name:'Forgot',

    setup(){

        const email = ref('');
        const notify = reactive({

            cls:'',
            message:''
        });

        const submit = async () => {

        try{

          await axios.post('passwordforgot', {
          email: email.value
        });

        notify.cls='success';
        notify.message='Email was sent, check inbox to proceed';

        } catch(e){

                notify.cls='alert';
                notify.message='Email provided does not exists';


            }
            
        }

        return {
            email,
            notify,
            submit
        }

    }

}

