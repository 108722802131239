
  import {computed} from 'vue';
  import {useStore} from "vuex";
  
  export default {
    name: "UserMenu",
    setup() {
      const store = useStore();
      const user = computed(() => store.state.User.user);
  
      return {
        user
      }
    }
  }
  